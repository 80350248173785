import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/buildhome/repo/packages/mdx/src/components/Layout/Default.tsx";
import { graphql } from 'gatsby';
import { IoMdBed, GiCastle, MdBook, MdMap } from 'react-icons/all';
import Section from '@kastle/ui/src/atoms/Section/Section';
import { SectionType } from '@kastle/ui/src/atoms/Section/Section.types';
import FullImage from '@kastle/components/src/components/Image/FullImage';
import { CustomLayout, Head, ContentRow, ContentCol, Separator, SmallSpace } from '@kastle/ui/src/organisms/Layouts/LegacyTypicalLayout';
import Heading from '@kastle/ui/src/atoms/Heading/Heading';
import Text from '@kastle/ui/src/atoms/Text/Text';
import LatestArticles from '../sections/LatestArticles';
import Presentation from '../sections/Presentation';
import { Routes } from '../routes';
export const query = graphql`
  query {
    bnb: file(
      relativePath: { eq: "2020/04/airbnb-chambres-dhotes-nancy.jpeg" }
    ) {
      childImageSharp {
gatsbyImageData(formats: WEBP,width: 500, layout: FULL_WIDTH)
      }
    }
    castle: file(relativePath: { eq: "chateau_de_morey.jpg" }) {
      childImageSharp {
gatsbyImageData(formats: WEBP,width: 500, layout: FULL_WIDTH)
      }
    }
    hall: file(
      relativePath: { eq: "location_de_salles_entre_nancy_et_metz.jpg" }
    ) {
      childImageSharp {
gatsbyImageData(formats: WEBP,width: 500, layout: FULL_WIDTH)
      }
    }
    hobby: file(
      relativePath: { eq: "place_stanislas_nancy_proche_chateau_de_morey.jpg" }
    ) {
      childImageSharp {
gatsbyImageData(formats: WEBP,width: 500, layout: FULL_WIDTH)
      }
    }
  }
`;
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const ButtonInternal = makeShortcode("ButtonInternal");
const layoutProps = {
  query,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <Presentation langKey={props.pageContext.langKey} mdxType="Presentation" />
    <Section type={SectionType.Secondary} mdxType="Section">
  <CustomLayout mdxType="CustomLayout">
    <Head mdxType="Head">
      <Separator mdxType="Separator">
        <Heading as="h2" mdxType="Heading">
          <strong>Bed and Breakfast</strong> between <strong>Nancy</strong> and <strong>Metz</strong>
        </Heading>
      </Separator>
      <Separator mdxType="Separator">
        <Text mdxType="Text">
          Between <strong>Nancy</strong> and <strong>Metz</strong>, on the edge of the woods, for an exceptional stopover. Spend a night or a weekend in this 16th century castle built on the ruins of a Templar convent. A unique and authentic place steeped in history.
        </Text>
      </Separator>
    </Head>
    <ContentRow mdxType="ContentRow">
      <ContentCol mdxType="ContentCol">
        <Separator mdxType="Separator">
          <Text mdxType="Text">
            The Karst family welcomes you in one of the 5 charming guest rooms. Stroll to the sound of birds in the park with century-old trees.
          </Text>
        </Separator>
        <Separator mdxType="Separator">
          <Text mdxType="Text">
            Exceptional panoramic view of the Natagne valley. Outdoor swimming pool, mini golf, table football, billiards, bowling alley. Closed and secured parking. SPA and Massage on reservation.
          </Text>
        </Separator>
        <Separator mdxType="Separator">
          <ButtonInternal to={Routes.en.BNB} mdxType="ButtonInternal">
            <SmallSpace mdxType="SmallSpace"><IoMdBed mdxType="IoMdBed" /></SmallSpace>
            Rooms
          </ButtonInternal>
        </Separator>
      </ContentCol>
      <ContentCol mdxType="ContentCol">
        <Separator mdxType="Separator"><FullImage image={props?.data?.bnb?.childImageSharp?.gatsbyImageData} alt="Bed and Breakfast near Nancy" mdxType="FullImage" /></Separator>
      </ContentCol>
    </ContentRow>
  </CustomLayout>
    </Section>
    <Section type={SectionType.Tertiary} mdxType="Section">
  <CustomLayout mdxType="CustomLayout">
    <Head mdxType="Head">
      <Separator mdxType="Separator">
        <Heading as="h2" mdxType="Heading">Le Château de Morey</Heading>
      </Separator>
      <Separator mdxType="Separator">
        <Text mdxType="Text">
          In the heart of Lorraine, in a renovated 16th century castle
        </Text>
      </Separator>
    </Head>
    <ContentRow mdxType="ContentRow">
      <ContentCol mdxType="ContentCol">
        <Separator mdxType="Separator">
          <Text mdxType="Text">
            Former residence of the Dukes of Lorraine in the 16th century, this castle was completely destroyed in 1985 by a fire. It was reborn from its ashes with the KARST family.  Rest and calm assured. Easy access, 5 km from the A31 exit 24.
          </Text>
        </Separator>
        <Separator mdxType="Separator">
          <Text mdxType="Text">
            Around this atypical bed and breakfast you can visit the city of Nancy which is only 18 km away and its many monuments and museums retracing the history of this city over the centuries or Metz and its famous Pompidou centre.
          </Text>
        </Separator>
        <Separator mdxType="Separator">
          <ButtonInternal to={Routes.en.CASTLE} mdxType="ButtonInternal">
            <SmallSpace mdxType="SmallSpace"><GiCastle mdxType="GiCastle" /></SmallSpace>
            Le Château
          </ButtonInternal>
        </Separator>
      </ContentCol>
      <ContentCol mdxType="ContentCol">
        <Separator mdxType="Separator"><FullImage image={props?.data?.castle?.childImageSharp?.gatsbyImageData} alt="Authentic Chateau Lorrain near Nancy" mdxType="FullImage" /></Separator>
      </ContentCol>
    </ContentRow>
  </CustomLayout>
    </Section>
    <Section type={SectionType.Secondary} mdxType="Section">
  <CustomLayout mdxType="CustomLayout">
    <Head mdxType="Head">
      <Separator mdxType="Separator">
        <Heading as="h2" mdxType="Heading">
          <strong>Hiring of halls</strong> in Lorraine between <strong>Nancy and Metz</strong>
        </Heading>
      </Separator>
      <Separator mdxType="Separator">
        <Text mdxType="Text">
          In an authentic setting, make your event an unforgettable moment. Open since 1998, we rent our rooms for weddings, seminars, birthdays, cocktails etc... Rental of 3 rooms, kitchen for caterer, part of the park with shelter for wine of honor.
        </Text>
      </Separator>
    </Head>
    <ContentRow mdxType="ContentRow">
      <ContentCol mdxType="ContentCol">
        <Separator mdxType="Separator">
          <Text mdxType="Text">
            If you are looking for a <strong>wedding venue</strong> in <strong>Lorraine</strong>, near <strong>Nancy</strong> and <strong>Metz</strong>. If you want an ideal setting to make your event an unforgettable moment! Come and discover the Château de Morey between <strong>Nancy and Metz city</strong>
          </Text>
        </Separator>
        <Separator mdxType="Separator">
          <Text mdxType="Text">
            It's a magical place, for an unforgettable wedding! The Château de Morey is an exceptional place to organize the most beautiful day of your life.
          </Text>
        </Separator>
        <Separator mdxType="Separator">
          <Text mdxType="Text">
            A 16th century castle for decoration with a garden with century-old trees. In a wonderfully preserved and maintained site, the castle of Morey can be discovered at the end of a majestic bridle path.
          </Text>
        </Separator>
        <Separator mdxType="Separator">
          <ButtonInternal to={Routes.en.SPACE_RENTAL} mdxType="ButtonInternal">
            <SmallSpace mdxType="SmallSpace"><MdBook mdxType="MdBook" /></SmallSpace>
            View more
          </ButtonInternal>
        </Separator>
      </ContentCol>
      <ContentCol mdxType="ContentCol">
        <Separator mdxType="Separator"><FullImage image={props?.data?.hall?.childImageSharp?.gatsbyImageData} alt="Hiring of rooms close to Nancy" mdxType="FullImage" /></Separator>
      </ContentCol>
    </ContentRow>
  </CustomLayout>
    </Section>
    <LatestArticles mdxType="LatestArticles" />
    <Section type={SectionType.Primary} mdxType="Section">
  <CustomLayout mdxType="CustomLayout">
    <Head mdxType="Head">
      <Separator mdxType="Separator">
        <Heading as="h2" mdxType="Heading">
          Leisure at the castle and discovery of <strong>Lorraine</strong> !
        </Heading>
      </Separator>
    </Head>
    <ContentRow mdxType="ContentRow">
      <ContentCol mdxType="ContentCol">
        <Separator mdxType="Separator">
          <Text mdxType="Text">
            To visit <strong>Lorraine</strong>, to travel, is to let yourself be surprised and moved by the other and elsewhere. And it is not always necessary to go to the end of the world...
          </Text>
        </Separator>
        <Separator mdxType="Separator">
          <ButtonInternal to={Routes.en.HOBBY} mdxType="ButtonInternal">
            <SmallSpace mdxType="SmallSpace"><MdMap mdxType="MdMap" /></SmallSpace>
            Discover
          </ButtonInternal>
        </Separator>
      </ContentCol>
      <ContentCol mdxType="ContentCol">
        <Separator mdxType="Separator"><FullImage image={props?.data?.hobby?.childImageSharp?.gatsbyImageData} alt="Place stanislas Nancy" mdxType="FullImage" /></Separator>
      </ContentCol>
    </ContentRow>
  </CustomLayout>
    </Section>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      